import React from 'react'
import trelloIconWhite from '../../images/trello-icon-white.svg'
import { SignupBar } from './SignupBar'

export const TrelloSignupBar: React.FC = () => (
  <SignupBar
    title="Trello"
    description="Sign up and connect in under a minute"
    iconSrc={trelloIconWhite}
    url={process.env.SIGNUP_URL}
  />
)
