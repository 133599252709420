import React from 'react'
import { Link, PageProps } from 'gatsby'
import { Lightning } from 'phosphor-react'
import { BasicLayout } from '../../layouts/basic'
import { SEOMeta } from '../../components/SEOMeta'
import { TrelloSignupBar } from '../../components/auth/TrelloSignupBar'
import trelloIcon from '../../images/trello-icon.svg'
import trelloLiveTyping from '../../images/trello-live-typing.png'
import trelloEdit from '../../images/trello-edit.png'
import trelloTransform from '../../images/trello-transform.png'

const Trello: React.FC<PageProps> = ({ location }) => (
  <BasicLayout>
    <SEOMeta
      title="Whiteboard for Trello"
      description="Connect Trello to Qualdesk to break free from kanban and work with your Trello cards on a flexible multiplayer whiteboard with two-way syncing"
      path={location.pathname}
    />
    <div className="max-w-[720px] mx-auto px-4">
      <header className="mt-8 px-2 text-center">
        <picture className="block mx-auto w-24 h-24 sm:w-32 sm:h-32">
          <img className="w-full h-full" src={trelloIcon} alt="Trello icon" />
        </picture>
        <h1 className="text-text-primary-light mt-4 text-3xl font-extrabold tracking-tight leading-tight sm:text-5xl">
          Trello
        </h1>
        <p className="text-text-primary-light mt-4 mx-auto max-w-xl text-base sm:mt-6 sm:text-lg">
          Connect Trello to Qualdesk to break free from kanban and work with
          your Trello cards on a flexible multiplayer whiteboard with two-way
          syncing.
        </p>
        <a
          className="group bg-background-one-light rounded-[20px] flex items-center mt-8 mx-auto p-1 w-max cursor-pointer sm:mt-10"
          href={process.env.SIGNUP_URL}
        >
          <div className="to-background-five-light from-background-four-light rounded-[16px] w-[90px] h-[48px] flex items-center justify-center bg-gradient-to-t">
            <Lightning
              className="text-icon-secondary-light group-hover:text-teal-400 w-6 h-6"
              weight="fill"
            />
          </div>
          <div className="text-text-secondary-light w-[90px] h-[48px] flex items-center justify-center text-base font-medium tracking-tight leading-tight">
            Connect
          </div>
        </a>
        <p className="text-text-secondary-light mt-4 text-sm">
          Sign up for Qualdesk and connect Trello in under a minute
        </p>
      </header>
      <figure className="bg-fuchsia-100 md:max-h-[348px] relative flex flex-col-reverse justify-between mb-4 mt-12 rounded-xl overflow-hidden sm:mt-14">
        <div className="ring-opacity-[16%] ring-fuchsia-800 absolute inset-0 rounded-xl ring-2 ring-inset" />
        <img
          className="max-w-[476px] max-h-[348px] self-end w-full"
          src={trelloLiveTyping}
          alt="Turn Trello into a multiplayer whiteboard with real time cursors and live typing "
        />
        <figcaption className="text-fuchsia-800 md:max-w-[400px] z-10 left-0 top-0 pt-8 px-8 text-2xl font-medium tracking-tight leading-tight sm:text-3xl md:absolute">
          Turn Trello into a multiplayer whiteboard with real time cursors and
          live typing
        </figcaption>
      </figure>
      <TrelloSignupBar />
      <div className="my-4 sm:grid sm:gap-6 sm:grid-cols-2 sm:my-8">
        <figure className="bg-lime-100 relative flex flex-col-reverse justify-between my-4 rounded-xl overflow-hidden sm:my-0">
          <div className="ring-opacity-[16%] ring-lime-800 absolute inset-0 rounded-xl ring-2 ring-inset" />
          <img
            className="max-w-[348px] max-h-[348px] self-end w-full"
            src={trelloEdit}
            alt="Edit Trello cards directly from the whiteboard"
          />
          <figcaption className="text-lime-800 p-8 text-2xl font-medium tracking-tight leading-tight sm:text-3xl">
            Edit Trello cards directly from the whiteboard
          </figcaption>
        </figure>
        <figure className="bg-orange-100 relative flex flex-col-reverse justify-between my-4 rounded-xl overflow-hidden sm:my-0">
          <div className="ring-opacity-[16%] ring-orange-800 absolute inset-0 rounded-xl ring-2 ring-inset" />
          <img
            className="max-w-[348px] max-h-[348px] self-end w-full"
            src={trelloTransform}
            alt="Transform stickies into Trello cards"
          />
          <figcaption className="text-orange-800 p-8 text-2xl font-medium tracking-tight leading-tight sm:text-3xl">
            Transform stickies into Trello cards
          </figcaption>
        </figure>
      </div>
      <footer className="mt-8">
        <Link
          className="border-border-normal-light active:bg-button-secondary-active-light hover:bg-background-three-light text-text-primary-light block px-8 py-4 text-center text-base font-medium tracking-tight leading-tight border rounded-lg"
          to="/integrations"
        >
          View all integrations
        </Link>
      </footer>
    </div>
  </BasicLayout>
)

export default Trello
